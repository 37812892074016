import React, { Component } from "react";
import { Input, Button } from "antd";

export default class TextSearchFilter extends Component {
  handleSearch = () => {
    //this.props.confirm();  // Request가 두 번 날아가서 주석 처리함. 이유는 모름. 주석 처리하면 검색 창이 닫히지 않음.
    this.props.setCustomFilteredInfo(
      this.props.dataIndex,
      this.props.selectedKeys
    ); // props 로 전달받은 부모 펑션 호출
  };

  handleReset = () => {
    //this.props.setCustomFilteredInfo(dataIndex, null);
    //this.props.clearFilters();
    this.props.setSelectedKeys([]);
  };

  render() {
    return (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          ref={(node) => {
            this.searchInput = node;
          }}
          value={this.props.selectedKeys}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.handleSearch();
            }
          }}
          onChange={(e) =>
            this.props.setSelectedKeys(e.target.value)
          }
        />
        <div style={{ marginTop: "5px", width: "100%", textAlign: "right" }}>
          <Button
            type="text"
            size="small"
            className="tt-btn"
            disabled={this.props.selectedKeys[0] ? false : true}
            onClick={(e) => this.handleReset()}
          >
            내용 삭제
          </Button>
          <Button
            type="primary"
            onClick={() => this.handleSearch()}
            size="small"
          >
            OK
          </Button>
        </div>
      </div>
    );
  }
}
