import React, { useState } from "react";
import { Modal, Button } from "antd";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";

const config = require("../../config/config.js");

const UploadFileViewer = (props) => {
  const [fileName, setFileName] = useState(props.fileName);
  const [isUplodFileViewerVisible, setIsUplodFileViewerVisible] = useState(
    props.isUplodFileViewerVisible
  );
  const [uploadedFiles] = useState(props.uploadedFiles);
  const [imageLoading, setImageLoading] = useState(true);
  const changeFile = (file) => {
    if (file !== fileName) {
      setImageLoading(true);
      setFileName(file);
    }
  };
  const closeModal = () => {
    setIsUplodFileViewerVisible(false);
  };

  useState(() => {
    setFileName(props.fileName)
  }, [props.fileName])

  return (
    <Modal
      title="File Viewer"
      visible={isUplodFileViewerVisible}
      cancelText="닫기"
      onCancel={closeModal}
      footer={null}
      width={1050}
    >
      <div className="header-img-list">
        {(() => {
          return uploadedFiles.map((file, index) => (
            <div className="header-img-item" key={file}>
              <img
                src={config.serverUrl +
                  "/api/getUploadedFile/" +
                  props.ideaIdx +
                  "/" +
                  props.writerUserId +
                  "/" +
                  file + "?isThumbnail=true"
                }
                title={file}
                alt={file}
                className={file === fileName ? "selected-img" : "normal-img"}
                onClick={() => changeFile(file)}
                onError={(event) => {
                  event.target.src = "/img/imgicon.png";
                  event.onerror = null;
                }}
              />
            </div>
          ));
        })()}
      </div>
      <div className="middle-img-download">
        <a
          href={
            config.serverUrl +
              "/api/getUploadedFile/" +
              props.ideaIdx +
              "/" +
              props.writerUserId +
              "/" +
              props.fileName
            }
        >
          <Button type="secondary" icon={<DownloadOutlined />}>
            {fileName}
          </Button>
        </a>
        {(props.userId === props.writerUserId || props.isAdmin) &&
          <Button
            type="primary"
            onClick={() => {
              props.openUploadFileEditor({ fileName });
            }}
            icon={<EditOutlined />}
          >
            이미지 편집
          </Button>
        }
      </div>
      <div className="contents-img">
        <img
          src={config.serverUrl +
            "/api/getUploadedFile/" +
            props.ideaIdx +
            "/" +
            props.writerUserId +
            "/" +
            fileName +
            "?" +
            props.rerenderKey 
          }
          title={fileName}
          alt={fileName}
          style={{
            maxWidth: 1000,
            display: imageLoading ? "none" : "initial",
            border: "1px solid #efefef",
          }}
          onLoad={() => setImageLoading(false)} // 이미지 로딩 완료 시 상태 변경
          onError={(event) => {
            event.target.src = "/img/imgicon.png";
            event.onerror = null;
          }}
        />
        {imageLoading ? (
          <span>
            <br />
            Loading...
          </span>
        ) : null}
      </div>
    </Modal>
  );
};

export default UploadFileViewer;
