import React, { Component } from "react";
import { Card, Row, Col, Form, Space, Button, Input, Radio, Select } from "antd";
import * as CommonFunc from "./components/functions/CommonFunc.js";
import FoodingService from "../service/FoodingService";
import CryptoJS from "crypto-js";

export default class FoodingLogin extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    loginId: "",
    loginPw: "",
    isAdminLogin: false,
    customEmail: "",
    email: "somansa.com"
  };

  // 관리자로 로그인 버튼을 클릭하면 상태에 따라 비밀번호창 나오는데, 이 상태값을 토글하는 함수
  changeLoginMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAdminLogin: !prevState.isAdminLogin,
    }));
  };

  login = () => {
    if (sessionStorage.getItem("admin")) {
      sessionStorage.removeItem("admin");
    }

    if (this.state.isAdminLogin) {
      this.loginWithAdmin();
    } else {
      if (this.state.loginId && this.state.loginId.indexOf("admin") !== -1) {
        CommonFunc.openNotification("error", "관리자로 로그인하려면 관리자 모드로 변경해주세요.");
        return;
      }
      if (!this.state.loginId || this.state.loginId.trim() === "") {
        CommonFunc.openNotification("error", "아이디를 입력해주세요.");
        return;
      }

      this.props.history.replace({
        pathname: "/fd/select/" + `${this.state.loginId}@${this.state.email === "custom" ? this.state.customEmail : this.state.email}`, // 스페이스 선텍 페이지로 이동
      });
    }
  };

  getConvertEncryptedPw = () => {
    // 암호화를 위한 랜덤 키값 생성
    let iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    let salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    let keySize = 128;
    let iterationCount = 10000;
    let passPhrase = "aaaaqqqqzzzz";

    // PBKDF2 키 생성
    let key128Bits100Iterations = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), { keySize: keySize / 32, iterations: iterationCount });
    let encrypted = CryptoJS.AES.encrypt(this.state.loginPw, key128Bits100Iterations, { iv: CryptoJS.enc.Hex.parse(iv) });
    let cipherText = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return {
      encryptedPw: cipherText,
      iv,
      salt,
    };
  };
  
  changeEmail = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      email: value,
    }));
  }

  // 관리자 로그인 -> 암호화해서 아이디, 비밀번호, iv, salt 등을 서버에 넘겨준다.
  loginWithAdmin = () => {
    if (!this.state.loginId || this.state.loginId.trim() === "") {
      CommonFunc.openNotification("error", "아이디를 입력해주세요.");
      return;
    }
    if (!this.state.loginPw || this.state.loginPw.trim() === "") {
      CommonFunc.openNotification("error", "비밀번호를 입력해주세요.");
      return;
    }
    let { encryptedPw, iv, salt } = this.getConvertEncryptedPw();
    let jsonData = { admin_id: this.state.loginId, admin_password: encryptedPw, iv, salt };

    FoodingService.login(jsonData)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("admin", true);
          this.props.history.replace({
            pathname: "/fd/admin",
            state: { userId: this.state.loginId },
          });

          CommonFunc.openNotification("success", "로그인 성공");
        } else {
          CommonFunc.openNotification("error", "잠시 후 다시 시도해주세요.");
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", err.response?.data);
      });
  };

  render() {
    return (
      <Row gutter={(10, 10)} align="middle" justify="center">
        <Card
          style={{
            width: this.state.isAdminLogin ? 320 : (this.state.email === "custom" ? 410 : 340),
            backgroundColor: "#f1f3f7",
            boxShadow: "0 0px 7px 0px rgba(0,0,0,0.035), 0 2px 9px -1px rgba(0,0,0,0.095), 0 4px 8px 1px rgba(0,0,0,0.04)",
            transform: "translate(-50%)",
            position: "absolute",
            top: "30%",
            left: "50%",
          }}
        >
          <Col span={21} push={3} style={{ marginBottom: 16 }}>
            <img src={`${process.env.PUBLIC_URL}/Fooding_Logo.svg`} width="210px" alt="Fooding" />
          </Col>
          <Col span={24} align="middle" style={{ marginBottom: 16 }}>
            <Radio.Group
              onChange={(e) => {
                this.setState({
                  isAdminLogin: e.target.value,
                });
              }}
              value={this.state.isAdminLogin}
            >
              <Space>
                <Radio value={false}>사용자</Radio>
                <Radio value={true}>관리자</Radio>
              </Space>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Form labelCol={{ span: 4 }} colon={false}>
              <Form.Item label={this.state.isAdminLogin ? "아이디" : "이메일"}>
                <Input type="text" style={{ width: this.state.isAdminLogin ? null : 100, marginRight: 5}} value={this.state.loginId} onPressEnter={(e) => this.login()} autoFocus={true} onChange={(e) => this.setState({ loginId: e.target.value })} />  
                {!this.state.isAdminLogin && 
                <>
                <span>@</span>
                {this.state.email === "custom" && <Input type="text" style={{ width: 100, marginLeft: 5 }} value={this.state.customEmail} autoFocus={true} onChange={(e) => this.setState({ customEmail: e.target.value })} />}
                  <Select
                    defaultValue={this.state.email}
                    style={{ width: this.state.email === "custom" ? 80 : 120, marginLeft: 5 }}
                    onChange={(value) => {
                      this.changeEmail(value);
                    }}
                  >
                      <Select.Option value="somansa.com">
                        <span>somansa.com</span>
                      </Select.Option>
                      <Select.Option value="naver.com">
                        <span>naver.com</span>
                      </Select.Option>
                      <Select.Option value="daum.net">
                        <span>daum.net</span>
                      </Select.Option>
                      <Select.Option value="custom">
                        <span>직접입력</span>
                      </Select.Option>
                </Select>
                </>
                }
              </Form.Item>
              {this.state.isAdminLogin && (
                <Form.Item label="비밀번호">
                    <Input type="password" value={this.state.loginPw} onPressEnter={(e) => this.login()} autoFocus={true} onChange={(e) => this.setState({ loginPw: e.target.value })} />
                </Form.Item>
              )}
            </Form>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Col>
                <Space size={2}>
                  <Button type="primary" onClick={this.login}>
                    Go
                  </Button>
                </Space>
              </Col>
              {/* <Col>
                <Space size={2}>
                  <Button onClick={this.changeLoginMode}>{this.state.isAdminLogin ? "사용자 로그인" : "관리자 로그인"}</Button>
                </Space>
              </Col> */}
            </Row>
          </Col>
        </Card>
      </Row>
    );
  }
}
