import React, { Component } from "react";
import { Card, Row, Col, Typography } from "antd";

export default class IdeaCountSummary extends Component {
  render() {
    const { Meta } = Card;
    return (
      <Row gutter={[4, 4]} align="middle" justify="center" style={{ textAlign: "center" }}>
        <Col span={24}>
          <Card>
            <Card.Grid onClick={() => this.props.clickIdeaCountSummary([2,3])}>
              <Meta title="채택 의견" />
              <Typography.Text strong={true} style={{ color: "#40a9ff", fontSize: 20 }}>
                {this.props.dataListSummary.all_issue_cnt}
              </Typography.Text>
            </Card.Grid>
            <Card.Grid onClick={() => this.props.clickIdeaCountSummary([1,2,3,4])}>
              <Meta title="피드백 의견" />
              <Typography.Text strong={true} style={{ color: "#1890ff", fontSize: 20 }}>
                {this.props.dataListSummary.all_feedback_cnt}
              </Typography.Text>
            </Card.Grid>
            <Card.Grid onClick={() => this.props.clickIdeaCountSummary([0,1,2,3,4])}>
              <Meta title="전체 의견" />
              <Typography.Text strong={true} style={{ color: "#096dd9", fontSize: 20 }}>
                {this.props.dataListSummary.all_idea_cnt}
              </Typography.Text>
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    );
  }
}
