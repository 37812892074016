import React, { Component } from "react";
import { Layout } from "antd";
import HeaderContainer from "../layouts/Header";
import FoodingList from "./FoodingList";
import FoodingSelectSpace from "./FoodingSelectSpace";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

/*
  실제 기능에 대한 화면은 
  FoodingLogin
  Fooding - FoodingList

  이런식으로 2개의 페이지임
  Fooding Component에서는 공통 헤더안에 여러개의 route로 나눠서, 추후에 변경될 화면에 대응.
*/
class Fooding extends Component {
  /*
   나중에 아이디 정보 가져오는 부분 따로 수정해야함 일단은 임시로 url 경로에서 따다가 변경했음
   현재 아이디 정보는 url에서 추출해서 가져오고 있는상태
  */
  state = {
    userId: sessionStorage.getItem("admin") ? "admin" : this.props?.match?.params.userId,
  };

  render() {
    return (
      <Layout>
        <Layout.Header>
          <HeaderContainer userId={this.state.userId} currentUrl={this.props.history.location?.pathname} history={this.props.history} />
        </Layout.Header>
        <Layout.Content style={{ padding: "10px" }}>
          <Switch>
            <Route exact path="/fd/select/:userId/" component={FoodingSelectSpace} />
            <Route exact path="/fd/list/:userId" component={FoodingList} />
            <Route exact path="/fd/list/:userId/:spaceIdx" component={FoodingList} />
            <Route exact path="/fd/list/:userId/:spaceIdx/:ideaIdx" component={FoodingList} />
            <Route
              path="/fd/admin"
              render={() => {
                if (sessionStorage.getItem("admin")) {
                  return <FoodingList isAdminLogin={this.props.history.location?.state?.isAdminLogin} history={this.props.history} />;
                } else {
                  return <div>관리자 권한이 없습니다.</div>;
                }
              }}
            />
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}
export default withRouter(Fooding);
