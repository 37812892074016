import React, { createRef } from "react";

export default class IdeaDropdown extends React.Component {
  wrapperRef = createRef();

  constructor(props) {
    super(props);

    this.state = {
      ideaDropdownDisplay: this.props.ideaDropdownDisplay,
      ideaDropdownTop: this.props.ideaDropdownTop,
      ideaDropdownLeft: this.props.ideaDropdownLeft,
    };
    //console.log('in constructor');
  }

  // 마우스 다른 위치 클릭시 창 제어
  // https://www.smashingmagazine.com/2021/03/outside-focus-click-handler-react-component/
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ ideaDropdownDisplay: "none" });
    }
  };

  handleKeyDown = (event) => {
    if (event.key === "Escape") {
      this.setState({ ideaDropdownDisplay: "none" });
    }
  };

  render() {
    const styles = {
      display: this.state.ideaDropdownDisplay,
      top: this.state.ideaDropdownTop,
      left: this.state.ideaDropdownLeft,
      position: "absolute",
      width: "130px",
      backgroundColor: "white",
      border: "solid 1px gray",
      zIndex: 1000
    };
    return (
      <div ref={this.wrapperRef} style={styles}>
        <div className="rc-virtual-list">
          <div className="rc-virtual-list-holder">
            <div>
              <div className="rc-virtual-list-holder-inner">
                {Array.from(this.props.ideaDropdownOptions).map((option, index) => (
                  <div
                    key={index}
                    className={
                      (this.props.ideaDropdownOptionType === "feedback_idx" ? index : index + 1) === this.props.ideaDropdownValue ? "ant-select-item ant-select-item-option ant-select-item-option-selected" : "ant-select-item ant-select-item-option mouseoverEffect"
                    }
                    title={option}
                    onClick={() => {
                      this.props.updateIdeaData(this.props.ideaDropdownOptionType, this.props.ideaDropdownOptionType === "feedback_idx" ? index : (option === "[공백]" ? 0 : index + 1));
                      this.props.updateIdeaDetailIdx(this.props.ideaDropdownOptionType, this.props.ideaDropdownOptionType === "feedback_idx" ? index : (option === "[공백]" ? 0 : index + 1));
                      this.setState({ ideaDropdownDisplay: "none" });
                    }}
                  >
                    <div className="ant-select-item-option-content">{option}</div>
                    <span className="ant-select-item-option-state"></span>
                  </div>
                ))}
              </div>
            </div>
            <div className="rc-virtual-list-scrollbar">
              <div className="rc-virtual-list-scrollbar-thumb"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
