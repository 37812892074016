import React, { Component, Fragment } from "react";
import moment from "moment";
import "moment/locale/ko";
import { Table, Spin, Badge, Space, Button, Tooltip, Row, Col, Popconfirm, Input } from "antd";
import { SearchOutlined, DeleteOutlined, LikeTwoTone, MessageTwoTone, LinkOutlined, StarTwoTone } from "@ant-design/icons";
import "antd/dist/antd.compact.css";
import FoodingService from "../../service/FoodingService";
import TextSearchFilter from "./TextSearchFilter.js";
import * as CommonFunc from "./functions/CommonFunc.js";

const config = require("../../config/config.js");

// 필터검색에 필요한 데이터 (페이징, 필터, 정렬 등에 사용)
let listFilterParams = {
  page_current: 1,
  page_size: config.page_size,
};

export default class LinkList extends Component {
  state = {
    // loading
    loading: false,

    // 검색 필터
    filteredInfo: null,
    customFilteredInfo: null,
    sortedInfo: null,

    // 의견 리스트
    dataList: [],

    // 페이징 처리 변수
    page_current: 1,
    page_size: config.page_size,
    page_total: 0,

    // 선택한 Row
    activeTableRowIndex: -1,
    spaceDataList: [],
    filterSpaceIdx: [],

    inputIdeaIdx: "",
    linkIdx: this.props.linkIdx,
  };

  // 해당 idea_idx의 list_idx를 null로 변경 후 link_cnt 한개 감소하고 그값 받아서 해당 리스트 전부 수정
  deleteLink = async (idea_idx, link_idx) => {
    const jsonData = {
      idea_idx,
      link_idx,
    };

    FoodingService.deleteLink(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "삭제가 완료됐습니다.", "");
          console.log(res.data);
          const linkCnt = res.data.link_cnt;
          // let link_cnt = res.data.rows[0].link_cnt;
          this.props.reloadIdeaLink(this.props.linkIdx, linkCnt, idea_idx, null, "D");

          if (this.props.ideaIdx === idea_idx) {
            this.setState({
              linkIdx: "",
              dataList: [],
            });
            this.props.updateLinkCntInDetail(0);
          } else {
            this.getLinkList(this.props.ideaIdx, this.props.linkIdx);
            this.props.updateLinkCntInDetail(linkCnt);
          }
          if (linkCnt === 1) {
            this.setState({
              linkIdx: "",
            });
          }
        } else {
          // Error
          CommonFunc.openNotification("error", "삭제를 실패했습니다..", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "삭제를 실패했습니다.", err?.response?.data);
      });
  };

  getLinkList = (idea_idx, link_idx, listFilterParams) => {
    let jsonData = {
      idea_idx,
      link_idx,
    };

    if (listFilterParams) {
      jsonData = {
        ...jsonData,
        ...listFilterParams,
      };
    }

    FoodingService.getLinkList(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          const findCurrentIdea = res.data?.filter((idea) => Number(idea.idea_idx) === Number(this.props.ideaIdx));

          this.setState({
            activeTableRowIndex: findCurrentIdea?.length > 0 ? findCurrentIdea[0].idea_idx : null,
            dataList: res.data,
            loading: false,
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  };
  /*
    1. 현재 보고 있는 상세 의견에 link_idx가 있다면? update
    현재 입력한 의견 idx 값을 찾고 link_idx가 있는지 조회 => 있으면 중복 link_idx 있다고 에러메시지 반환 
    없으면 입력한 의견 idx의 link_idx 값을 변경하고 link_cnt를 1증가 시키고 그값을 받아서 다른 link_idx에도 변경

    2. 현재 보고 있는 상세 의견에 link_idx가 없다면? insert
    현재 입력한 의견 idx 값을 찾고 link_idx가 있는지 조회 => 있으면 그 link_idx 값으로 변경하고 link_cnt 를 1증가 시키고 그값을 받아서 다른 link_idx에도 변경

    없으면 새로운 링크 생성 후에 상세의견이랑 입력한 idx의 link_idx 변경
    그리고 그 link_cnt 받아서 다른 link_idx에도 변경

  */
  linkIdea = () => {
    if (this.state.inputIdeaIdx && this.state.inputIdeaIdx.trim() === "") {
      alert("연결할 의견 idx를 입력해주세요.");
      return;
    }
    if (isNaN(this.state.inputIdeaIdx)) {
      alert("숫자를 입력해주세요.");
      return;
    }
    if (parseInt(this.props.ideaIdx) === parseInt(this.state.inputIdeaIdx)) {
      alert("자신의 idx로 링크할 수 없습니다.");
      return;
    }

    // link_idx 가 있다는건 기존에 링크된 것이 있다는 의미이므로 연결을 업데이트만 해주면 됨
    if (this.state.linkIdx) {
      this.updateLink();
    } else {
      // link_idx가 없으면 새로 생성하고 두개를 같이 연결해줌
      this.addNewLink();
    }
  };

  updateLink = () => {
    const jsonData = {
      idea_idx: this.state.inputIdeaIdx,
      link_idx: this.props.linkIdx,
    };
    FoodingService.updateLink(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          CommonFunc.openNotification("success", "링크 연결했습니다.", "");
          let linkIdx = this.props.linkIdx;
          let linkCnt = res.data.rows[0].link_cnt;
          this.props.reloadIdeaLink(linkIdx, linkCnt, this.state.inputIdeaIdx);
          this.props.updateLinkCntInDetail(linkCnt);

          this.getLinkList(this.props.ideaIdx, this.props.linkIdx);
          this.setState({
            inputIdeaIdx: "",
          });
        } else CommonFunc.openNotification("error", "수정을 실패했습니다.", res.status);
      })
      .catch((err) => {
        console.dir(err);

        CommonFunc.openNotification("error", "수정을 실패했습니다.", err?.response?.data);
      });
  };
  addNewLink = () => {
    const jsonData = {
      idea_idx1: this.props.ideaIdx,
      idea_idx2: this.state.inputIdeaIdx,
    };
    FoodingService.addNewLink(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          let linkIdx = res.data.rows[0].link_idx;
          let linkCnt = res.data.rows[0].link_cnt;

          this.setState({
            linkIdx,
          });
          this.props.reloadIdeaLink(linkIdx, linkCnt, this.props.ideaIdx, this.state.inputIdeaIdx);
          this.props.updateLinkCntInDetail(linkCnt);

          this.getLinkList(this.props.ideaIdx, linkIdx);
          this.setState({
            inputIdeaIdx: "",
          });
        } else CommonFunc.openNotification("error", "추가 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "추가 실패했습니다.", err?.response?.data);
      });
  };
  // DB 조회 (필터 검색)
  filterSearch = async (type) => {
    // console.log("filterSearch : " + JSON.stringify(listFilterParams));
    FoodingService.getIdeaList(JSON.stringify(listFilterParams))
      .then((res) => {
        if (res.status === 200) {
          let dataList = res.data;
          let filteredSpaceList = dataList.filter((data, idx, arr) => {
            return arr.findIndex((item) => item.space_title === data.space_title) === idx;
          });

          this.setState({
            dataList,
            spaceDataList: filteredSpaceList,
            loading: false,
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });

    FoodingService.getIdeaListCount(JSON.stringify(listFilterParams))
      .then((res) => {
        if (res.status === 200) {
          console.log("list count ", res.data[0]);
          this.setState({
            dataListSummary: res.data[0],
          });
        } else {
          CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  };

  // 마운트 시 호출
  componentDidMount() {
    this.getLinkList(this.props.ideaIdx, this.props.linkIdx);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isRefreshLinkList) {
      this.props.clearIsRefreshLinkList();
      this.getLinkList(this.props.ideaIdx, this.props.linkIdx);
    }
  }

  // 커스텀 필터 TextSearchFilter 에서 호출될 펑션
  setCustomFilteredInfo = (dataIndex, value) => {
    this.setState({
      customFilteredInfo: {
        ...this.state.customFilteredInfo,
        [dataIndex]: value,
      },
      loading: true,
    });
    // Assign filter data
    listFilterParams = {
      ...listFilterParams,
      [dataIndex]: value,
      page_current: 1,
      page_size: this.state.page_size,
    };
    this.getLinkList(this.props.ideaIdx, this.state.linkIdx, listFilterParams);
  };

  // List 변경 이벤트 처리 핸들러
  handleIdeaListChange = (pagination, filters, sorter) => {
    // console.log("test filters", filters);
    // console.log("test sorter", sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      // loading: true,
    });
    listFilterParams = filters;
    listFilterParams = {
      // 파라미터 전달용 value 세팅 : filters에는 커스텀 필터 값이 포함되어 있지 않아 병합 처리
      ...listFilterParams,
      ...sorter,
      ...this.state.customFilteredInfo,
      user_id: this.state.userId,
      page_current: this.state.page_current,
      page_size: this.state.page_size,
    };

    if (!listFilterParams.space_idx) {
      listFilterParams.space_idx = [this.state.selectedSpaceIdx];
    }

    // console.log("handle", listFilterParams);
    this.getLinkList(this.props.ideaIdx, this.state.linkIdx, listFilterParams);
  };
  // 선택된 행인 경우 색 반전 클래스 지정
  setActiveRowClass = (record, rowIndex) => {
    return record.idea_idx === this.state.activeTableRowIndex ? "activeTableRow" : "";
  };

  removeActiveRowClass = () => {
    this.setState({ activeTableRowIndex: -1 });
  };

  handleLinkListRow = (record, rowIdx) => {
    return {
      onClick: (e) => {
        this.setState({ activeTableRowIndex: record.idea_idx });
        this.props.clickLinkListRow({
          ideaTitle: record.idea_title,
          ideaContents: record.idea_contents,
          ideaIdx: record.idea_idx,
          ideaScore: record.idea_score,
          priorityIdx: record.priority_idx,
          typeIdx: record.idea_type_idx,
          categoryIdx: record.idea_category_idx,
          feedbackIdx: record.feedback_idx,
          writerUserId: record.user_id,
          likeCnt: record.like_cnt,
        });
      },
    };
  };
  // 페이징 핸들러
  handlePagination = (page, pageSize) => {
    console.log("handlePagination ", page);
    console.log("handlePagination ", pageSize);
    if (this.state.page_size !== pageSize) page = 1;

    this.setState({
      page_current: page,
      page_size: pageSize,
      loading: true,
    });
    listFilterParams = {
      ...listFilterParams,
      page_current: page,
      page_size: pageSize,
    };
    this.filterSearch("pagination");
  };

  render() {
    let { sortedInfo, filteredInfo, customFilteredInfo } = this.state;
    sortedInfo = sortedInfo || {};

    // 나중에 공백 있고 없고를 정해서 처리해야함
    const scoreOptions = [...config.scoreList, "[공백]"];
    const productNameOptions = [...config.productNameList, "[공백]"];
    const problemTypeOptions = [...config.problemTypeList, "[공백]"];
    const priorityOptions = [...config.priorityList, "[공백]"];
    const feedbackOptions = [...config.feedbackList];

    const columns = [
      // {
      //   dataIndex: "idea_idx",
      //   key: "idea_idx",
      //   width: 0
      // },
      {
        title: "등록 일시",
        dataIndex: "create_date",
        key: "create_date",
        width: 90,
        sorter: true,
        // sorter: (a, b) => moment(a.create_date).format("YYYYMMDDHHmmssms") - moment(b.create_date).format("YYYYMMDDHHmmssms"),
        sortOrder: sortedInfo.columnKey === "create_date" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (create_date, data) => (
          <div>
            <Tooltip
              title={
                <div>
                  {moment(data.create_date).format("YYYY-MM-DD HH:mm:ss")}
                  {sessionStorage.getItem("admin") && (
                    <Button
                      type="text"
                      size="small"
                      className="tt-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Popconfirm title="해당 링크를 삭제하시겠습니까?" onConfirm={(value, obj) => this.deleteLink(data.idea_idx, this.state.linkIdx)} okText="삭제" cancelText="취소">
                        <DeleteOutlined />
                      </Popconfirm>
                    </Button>
                  )}
                  {/* 일단 현재는 관리자만 삭제할 수 있음 */}
                  {/* {sessionStorage.getItem("admin") && (
                    <Button
                      type="text"
                      size="small"
                      className="tt-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Popconfirm title="해당 아이디어를 삭제하시겠습니까?" onConfirm={(value, obj) => this.deleteLink(data.idea_idx)} okText="삭제" cancelText="취소">
                        <DeleteOutlined />
                      </Popconfirm>
                    </Button>
                  )} */}
                </div>
              }
              color="#7d8282"
              align="center"
              placement="top"
            >
              <span>{moment(moment(data.create_date)).fromNow()}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        title: "스페이스 이름",
        dataIndex: "space_title",
        key: "space_title",
        width: 150,
        ellipsis: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "space_title" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "제품명",
        dataIndex: "idea_type_idx",
        key: "idea_type_idx",
        width: 90,
        ellipsis: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "idea_type_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: productNameOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.idea_type_idx ? filteredInfo.idea_category_idx : null,
        render: (idea_type_idx, data) => <div className="tdText">{productNameOptions[idea_type_idx - 1]}</div>,
      },
      // {
      //   title: "메뉴명 또는 화면 위치",
      //   dataIndex: "idea_title",
      //   key: "idea_title",
      //   width: 180,
      //   ellipsis: true,
      //   sorter: true,
      //   sortOrder: sortedInfo.columnKey === "idea_title" && sortedInfo.order,
      //   sortDirections: ["descend", "ascend"],
      //   filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="idea_title" />,
      //   filteredValue: customFilteredInfo?.idea_title ? [customFilteredInfo?.idea_title] : undefined,
      //   filterIcon: (filtered) => (
      //     <SearchOutlined
      //       style={{
      //         color: customFilteredInfo?.idea_title ? "#1890ff" : undefined,
      //       }}
      //     />
      //   ),
      // },
      {
        title: "제안 의견",
        dataIndex: "idea_contents",
        key: "idea_contents",
        width: 400,
        ellipsis: true,
        filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="idea_contents" />,
        filteredValue: customFilteredInfo?.idea_contents ? customFilteredInfo?.idea_contents : null,
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: customFilteredInfo?.idea_contents && customFilteredInfo?.idea_contents.length > 0 ? "#1890ff" : undefined,
            }}
          />
        ),
        render: (idx, data) => (
          <>
            <Tooltip title={data.idea_contents} color="#7d8282" placement="topLeft">
              {data.idea_contents}
            </Tooltip>
          </>
        ),
      },
      {
        title: "작성자",
        dataIndex: "user_id",
        key: "user_id",
        width: 100,
        ellipsis: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "user_id" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="writer_id" />,
        filteredValue: customFilteredInfo?.writer_id ? customFilteredInfo?.writer_id : null,
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: customFilteredInfo?.writer_id && customFilteredInfo?.writer_id.length > 0 ? "#1890ff" : undefined,
            }}
          />
        ),
        // filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="user_id" />,
        // filteredValue: customFilteredInfo?.user_id ? [customFilteredInfo?.user_id] : undefined,
        // filterIcon: (filtered) => (
        //   <SearchOutlined
        //     style={{
        //       color: customFilteredInfo?.user_id ? "#1890ff" : undefined,
        //     }}
        //   />
        // ),
      },

      {
        title: "좋아요",
        dataIndex: "like_cnt",
        key: "like_cnt",
        width: 70,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "like_cnt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (like_cnt, data) => (
          <Space>
            <LikeTwoTone />
            {like_cnt}
          </Space>
        ),
      },
      {
        title: "댓글",
        dataIndex: "comment_cnt",
        key: "comment_cnt",
        width: 70,
        className: "fixed-col",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "comment_cnt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (comment_cnt, data) => (
          <Space>
            <MessageTwoTone />
            {comment_cnt}
          </Space>
        ),
      },
      {
        title: "링크",
        dataIndex: "link_cnt",
        key: "link_cnt",
        width: 70,
        className: "fixed-col",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "link_cnt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (link_cnt, data) => (
          <>
            <Button icon={<LinkOutlined />} style={{ cursor: "default" }} type="link"></Button>
            {link_cnt}
          </>
        ),
      },
      {
        title: "검토 현황",
        dataIndex: "feedback_idx",
        key: "feedback_idx",
        width: 100,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "feedback_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: feedbackOptions.map((label, index) =>  ({ text: label, value: index })),
        filteredValue: filteredInfo?.feedback_idx ? filteredInfo.feedback_idx : null,
        render: (feedback_idx, data) => <div className="tdText">{feedbackOptions[feedback_idx]}</div>,
      },
      {
        title: "의견 분류",
        dataIndex: "idea_category_idx",
        key: "idea_category_idx",
        width: 100,
        ellipsis: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "idea_category_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: problemTypeOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.idea_category_idx ? filteredInfo.idea_category_idx : null,
        render: (idea_category_idx, data) => <div className="tdText">{problemTypeOptions[idea_category_idx - 1]}</div>,
      },
      {
        title: "중요도",
        dataIndex: "priority_idx",
        key: "priority_idx",
        width: 90,
        className: "fixed-col",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "priority_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: scoreOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.priority_idx ? filteredInfo.priority_idx : null,
        render: (priority_idx, data) => (
          <div className="tdText">
            <Badge
              color={
                priorityOptions[priority_idx - 1] === "높음" ? "#f50" : config.priorityList[priority_idx - 1] === "보통" ? "#87d068" : config.priorityList[priority_idx - 1] === "낮음" ? "#efefef" : ""
              }
              text={config.priorityList[priority_idx - 1]}
            />
          </div>
        ),
      },
      {
        title: "기여도",
        dataIndex: "idea_score",
        key: "idea_score",
        width: 90,
        className: "fixed-col",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "idea_score" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: scoreOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.idea_score ? filteredInfo.idea_score : null,
        render: (score_idx, data) => (
          <div className="tdText">
            {score_idx === 0 ? (
              ""
            ) : (
              <Space>
                <StarTwoTone twoToneColor="#FFB900" />
                {scoreOptions[score_idx - 1]}
              </Space>
            )}
          </div>
        ),
      },
      // {
      //   title: "채택 정보",
      //   dataIndex: "issue_number",
      //   key: "issue_number",
      //   width: 100,
      //   className: "fixed-col",
      //   ellipsis: true,
      //   //sorter: (a, b) => a.issue_number.length - b.issue_number.length,
      //   sorter: true,
      //   sortOrder: sortedInfo.columnKey === "issue_number" && sortedInfo.order,
      //   sortDirections: ["descend", "ascend"],
      //   filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="issue_number" />,
      //   filteredValue: customFilteredInfo?.issue_number ? [customFilteredInfo?.issue_number] : null,
      //   filterIcon: (filtered) => (
      //     <SearchOutlined
      //       style={{
      //         color: customFilteredInfo?.issue_number ? "#1890ff" : null,
      //       }}
      //     />
      //   ),
        /*render: (number, data) => ({
          
          <>
            <div className="f-left">
              {number === "임시" ? (
                number
              ) : (
                <a href={"http://redmine.somansa.com/redmine/issues/show/" + number} target="_blank" rel="noopener noreferrer">
                  #{number}
                </a>
              )}
            </div>
            <div className="ta-right w-100">
              {number !== "임시" ? (
                <Button
                  type="default"
                  icon={<SyncOutlined />}
                  className="icon-gray"
                  shape="circle"
                  size="small"
                  title="일감 동기화"
                  onClick={() =>
                    this.syncTargetCompare(
                      data.issue_idx,
                      data.issue_number,
                      data.issue_status,
                      data.issue_subject,
                      data.issue_assigned_to
                    )
                  }
                />
              ) : (
                ""
              )}
              <Button
                type="default"
                icon={<BorderlessTableOutlined />}
                className="icon-gray"
                shape="circle"
                size="small"
                title="일감 번호 수정"
                onClick={() =>
                  this.modalHandler.showIssueNumberModal(data.issue_idx, number)
                }
              /> 
           </div>
          </>
        ),
      }, 
        }),*/
      // },
    ];

    const tableLoading = {
      spinning: this.state.loading,
      indicator: <Spin />,
    };

    return (
      <Fragment>
        <Row gutter={[20, 20]}>
          {sessionStorage.getItem("admin") && (
            <Col span={24}>
              <Input.Group compact>
                <Input
                  style={{ width: "calc(100% - 70px)" }}
                  type="text"
                  value={this.state.inputIdeaIdx}
                  onChange={(e) => {
                    this.setState({ inputIdeaIdx: e.target.value });
                  }}
                  placeholder="의견 번호"
                />
                <Button type="primary" onClick={() => this.linkIdea()}>
                  연결하기
                </Button>
              </Input.Group>
            </Col>
          )}
          {/* <Col span={16}>
            <Input
              type="text"
              value={this.state.inputIdeaIdx}
              onChange={(e) => {
                this.setState({ inputIdeaIdx: e.target.value });
              }}
            />
          </Col>
          <Col span={8}>
            <button onClick={this.linkIdea}>연결하기</button>
          </Col> */}
          <Col span={24}>
            <Table
              key="defaultTable"
              columns={columns}
              size="middle"
              dataSource={this.state.dataList}
              onChange={this.handleIdeaListChange}
              rowClassName={this.setActiveRowClass}
              showSorterTooltip={false}
              rowKey="idea_idx"
              onRow={this.handleLinkListRow}
              pagination={false}
              scroll={{ x: 1300 }}
              loading={tableLoading}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
