import { notification } from "antd";

/**
 * 지정된 유형, 메시지, 설명, 버튼, 키로 알림을 엽니다.
 *
 * @param {string} type - 알림의 유형입니다. 가능한 값은 'success', 'info', 'warning', 'error'입니다.
 * @param {string} message - 알림에 표시될 메시지입니다.
 * @param {string} description - 알림에 표시될 설명입니다.
 * @param {ReactNode} button - 알림에 표시될 버튼입니다.
 * @param {string} key - 알림의 고유 키입니다.
 * @returns {void}
 */
export function openNotification(type, message, description, button, key) {
  notification[type]({
    message: message,
    description: description,
    placement: "bottomLeft",
    duration: 5,
    btn: button,
    key: key,
  });
}

/**
 * GUID (전역 고유 식별자)를 생성합니다.
 *
 * @returns {string} 생성된 GUID.
 */
export function generateGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
