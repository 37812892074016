import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import FoodingService from "../service/FoodingService";
import * as CommonFunc from "./components/functions/CommonFunc.js";
import moment from "moment";

export default class FoodingSelectSpace extends Component {
  state = {
    editableSpaceList: [], // 편집가능한 스페이스 리스트
    userId: this.props.match?.params?.userId,
  };
  componentDidMount() {
    this.getEditableSpaceList();
  }

  getEditableSpaceList = () => {
    const jsonData = {
      user_id: this.state.userId,
    };

    FoodingService.getEditableSpaceList(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          /* 
            공개여부를 우선으로 조건 결정
            1) 공개여부가 공개인 경우 - Open 상태이거나 의견보기설정이 'close시 모든의견보기'면서 종료된 상태일때 보여줌
            2) 공개여부가 특정대상인 경우 - adminUserList에 있는 user_id에 해당 user_id가 있어야 함
            3) 공개여부가 비공개인경우 아예 안보여줌
            
            특정대상 의견작성 + 내의견만 보기 스페이스 제외

            내가 의견 쓴게 하나라도 있으면 스페이스가 종료된 뒤에도 들어갈 수 있어야 함 (전체 공개인 경우)

            그러면서 무조건 비공개는 못봄
          */
          const filteredList = res.data.filter(
            (space) =>
              (!(space.space_target_type === 1 && space.space_share_type === 0) &&
                space.space_publish_type === 0 &&
                (space.space_state === 0 ||
                  space.space_share_type === 2 ||
                  ((space.space_share_type === 1 || (space.space_share_type === 0 && Number(space.idea_cnt) > 0)) &&
                    (space.space_state === 1 || (space.space_date_type === 1 && moment().diff(moment(space.space_end_date)) > 0))))) ||
              (space.space_publish_type === 1 && config.adminUserList.includes(this.state.userId))
          );

          this.setState({
            editableSpaceList: filteredList,
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  };

  render() {
    if (this.state.editableSpaceList && this.state.editableSpaceList.length === 0) {
      return (
        <div>
          <Link to="/login">로그인 창으로</Link>
        </div>
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "830px", backgroundColor: "white" }}>
        {this.state.editableSpaceList.map((space) => (
          <div key={space.space_idx} style={{ flex: 1, display: "flex", fontSize: "20px", flexDirection: "column", justifyContent: "center" }}>
            <Link to={`/fd/list/${this.state.userId}/${space.space_idx}`}>{space.space_title}</Link>
          </div>
        ))}
      </div>
    );
  }
}
