import React, { Component} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/antd.compact.css";
import "./css/default.css";
import Fooding from "./pages/Fooding";
import FoodingLogin from "./pages/FoodingLogin";

class App extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={FoodingLogin} />
          <Route exact path="/login" component={FoodingLogin} />
          <Route exact path="/fd/select/:userId/" component={Fooding} />
          <Route exact path="/fd/list/:userId/" component={Fooding} />
          <Route path="/fd/list/:userId/:spaceIdx" component={Fooding} />
          <Route path="/fd/list/:userId/:spaceIdx/:ideaIdx" component={Fooding} />
          <Route path="/fd/admin" component={Fooding} />
        </Switch>
      </Router>
    );
  }
}
export default App;
