import Uploady from "@rpldy/uploady";
import UploaderPasteArea from "./UploaderPasteArea";

const config = require("../../config/config.js");

const UploaderWithPaste = (props) => {
  return (
    <Uploady
      destination={{
        url: config.serverUrl + "/api/fileUpload",
      }}
      accept="image/*"
      encodeFileNames={true}
      autoUpload={true}
    >
      <UploaderPasteArea
        autoUpload={true}
        params={{ idea_idx: props.ideaIdx, user_id: props.writerUserId }}
        ideaIdx={props.ideaIdx}
        userId={props.userId}
        writerUserId={props.writerUserId}
        isAdmin={props.isAdmin}
      />
    </Uploady>
  );
};

export default UploaderWithPaste;
