import React, { Component, Fragment } from "react";
import moment from "moment";
import "moment/locale/ko";
import { Table, Spin, Pagination, PageHeader, Badge, Space, Button, Tooltip, Row, Col, Popconfirm, Card } from "antd";
import { SearchOutlined, DeleteOutlined, LikeTwoTone, MessageTwoTone, LinkOutlined, StarTwoTone } from "@ant-design/icons";
import "antd/dist/antd.compact.css";
import FoodingService from "../service/FoodingService";
import TextSearchFilter from "./components/TextSearchFilter.js";
import * as CommonFunc from "./components/functions/CommonFunc.js";
import IdeaDetailModal from "./components/IdeaDetailModal";
import IdeaCountSummary from "./components/IdeaCountSummary";
import SpaceDropdown from "./components/SpaceDropdown";
import SelectedSpaceSummary from "./components/SelectedSpaceSummary";

const config = require("../config/config.js");

// 필터검색에 필요한 데이터 (페이징, 필터, 정렬 등에 사용)
let listFilterParams = {
  page_current: 1,
  page_size: config.page_size,
};

export default class FoodingList extends Component {
  state = {
    // loading
    loading: false,
    // 검색 필터
    filteredInfo: null,
    customFilteredInfo: null,
    sortedInfo: null,

    // 의견 리스트
    dataList: [],
    // 의견에 대한 개략적인 카운트 정보를 가져옴 (전체 리스트 수, 제안의견 수, 피드백의견수, 채택의견 수)
    dataListSummary: [],

    // 페이징 처리 변수
    page_current: 1,
    page_size: config.page_size,
    page_total: 0,

    // 모달창 Show/hide를 위한 변수
    isIdeaDetailModalVisible: null,
    ideaDetailModalRerender: 0,

    // spaceDataList: [],
    /*
      각각 url을 통해 받아오는 params들, 로그인한 아이디, 스페이스 idx 
      그 중 selectedSpaceIdx는 전체스페이스를 보여줄지 해당 스페이스를 보여줄지 판단하는데 사용
    */
    userId: this.props.history?.location?.state?.userId ? this.props.history?.location?.state?.userId : this.props.match?.params?.userId,
    selectedSpaceIdx: this.props.match?.params?.spaceIdx,
    filterSpaceIdx: [],
    selectedSpaceInfo: null,
    // 선택한 Row
    activeTableRowIndex: -1,

    // 현재 의견 상세 정보 , 상세정보 모달 뜰 때 같이 넘겨준다.
    ideaDetailData: null,

    // 현재 들어온 스페이스 번호
    currentEnterSpace: "",
    isNewEdit: false,

    showOnlyMyData: sessionStorage.getItem("admin") ? false : true,

    isLink: false
  };

  clickIdeaCountSummary = (idx) => {
    
    if (idx !== "") {
      listFilterParams = {
        ...listFilterParams,
        feedback_idx: idx,
        page_current: 1,
        page_size: this.state.page_size,
      };
    }
    this.filterSearch(true);
  }

  // 모든 필터 초기화
  initFilter = () => {
    this.setState({
      sortedInfo: null,
      filteredInfo: null,
      customFilteredInfo: null,
      page_current: 1,
      loading: true,
    });

    listFilterParams = {
      // user_id: this.state.userId,
      page_current: this.state.page_current,
      page_size: this.state.page_size,
    };

    // this.filterSearch();
  };

  /* 의견 상세 모달 추가*/
  showIdeaDetailModal = (idea_idx, record) => {
    // 의견 모달 제목 무조건 넣게 수정
    if (record) {
      record.space_title = record.space_title ? record.space_title : this.state.selectedSpaceInfo?.space_title;
    }
    let rerender = this.state.ideaDetailModalRerender;
    this.setState({
      isIdeaDetailModalVisible: true,
      idea_idx: idea_idx,
      ideaDetailModalRerender: ++rerender,
      ideaDetailData: record,
    });
  };

  changeEditState = () => {
    this.setState({
      isNewEdit: false,
    });
  };

  updateHandler = {
    updateIdeaData: async (idea_idx, name, value) => {
      let jsonData = {
        idea_idx: idea_idx,
        [name]: value,
      };
      this.updateHandler.updateIdea(idea_idx, jsonData);
    },
    reloadIdeaLink: (link_idx, link_cnt, idea_idx1, idea_idx2, type) => {
      //console.log(jsonData);
      // dataList 정보 갱신 처리
      let updatedDataList = JSON.parse(JSON.stringify(this.state.dataList));
      let filteredIdeaIdxList = this.state.dataList.filter((idea) => idea.link_idx === link_idx);

      filteredIdeaIdxList.map((idea) => {
        let index = updatedDataList.findIndex((index) => parseInt(index["idea_idx"]) === parseInt(idea.idea_idx)); // dataList 에서 issue_idx 일치하는 행의 index 추출

        if (link_cnt === 1) {
          updatedDataList[index] = { ...updatedDataList[index], link_cnt, link_idx: null }; // index 행의 정보만 변경
        } else {
          updatedDataList[index] = { ...updatedDataList[index], link_cnt }; // index 행의 정보만 변경
        }

        return null;
      });

      // console.log(link_idx, link_cnt, idea_idx1, idea_idx2);
      if (idea_idx1) {
        let index = updatedDataList.findIndex((index) => parseInt(index["idea_idx"]) === parseInt(idea_idx1)); // dataList 에서 issue_idx 일치하는 행의 index 추출
        updatedDataList[index] = { ...updatedDataList[index], link_cnt: type === "D" ? 0 : link_cnt, link_idx: type === "D" ? null : link_idx }; // index 행의 정보만 변경
      }
      if (idea_idx2) {
        let index = updatedDataList.findIndex((index) => parseInt(index["idea_idx"]) === parseInt(idea_idx2)); // dataList 에서 issue_idx 일치하는 행의 index 추출
        updatedDataList[index] = { ...updatedDataList[index], link_cnt, link_idx }; // index 행의 정보만 변경
      }

      this.setState({
        dataList: updatedDataList,
      });
    },
    // 상세화면에서 댓글 추가나 삭제가 발생했을 때 내부적으로 개수 수정, 전체 피드백 숫자도 수정
    reloadIdeaComment: (idea_idx, comment_cnt) => {
      // let allCommentCnt = parseInt(this.state.dataListSummary.all_comment_cnt);
      let updatedDataList = JSON.parse(JSON.stringify(this.state.dataList));
      let index = updatedDataList.findIndex((index) => parseInt(index["idea_idx"]) === parseInt(idea_idx)); // dataList 에서 idea_idx 일치하는 행의 index 추출
      // let changeAllCommentCnt = 1;

      // if (updatedDataList[index].comment_cnt > comment_cnt) {
      //   changeAllCommentCnt = -1;
      // }

      // let updatedDataListSummary = { ...this.state.dataListSummary, all_comment_cnt: allCommentCnt + changeAllCommentCnt };

      updatedDataList[index] = { ...updatedDataList[index], comment_cnt }; // index 행의 정보만 변경

      this.setState({
        dataList: updatedDataList,
      }); // dataList 변경
    },
    // 상세화면에서 좋아요 추가나 삭제가 발생했을 때 내부적으로 개수 수정
    reloadIdeaLike: (idea_idx, like_cnt) => {
      let updatedDataList = JSON.parse(JSON.stringify(this.state.dataList));
      let index = updatedDataList.findIndex((index) => parseInt(index["idea_idx"]) === parseInt(idea_idx)); // dataList 에서 issue_idx 일치하는 행의 index 추출

      updatedDataList[index] = { ...updatedDataList[index], like_cnt }; // index 행의 정보만 변경

      this.setState({
        dataList: updatedDataList,
      });
    },
    // 요약 개수 업데이트 함수
    reloadSummaryCnt: (summaryCntList) => {
      let updatedDataListSummary = { ...this.state.dataListSummary};
      summaryCntList.map(summaryCntInfo => {
        var currentCnt = parseInt(this.state.dataListSummary[summaryCntInfo.idxName]);
        updatedDataListSummary[summaryCntInfo.idxName] = currentCnt + summaryCntInfo.amount;
      });
      
      this.setState({
        dataListSummary: JSON.parse(JSON.stringify(updatedDataListSummary)),
      });
    },
    // 리스트 리로드
    reloadIdeaList: (idea_idx, jsonData) => {
      //console.log(jsonData);
      // dataList 정보 갱신 처리
      let updatedDataList = this.state.dataList;
      let index = updatedDataList.findIndex((index) => parseInt(index["idea_idx"]) === parseInt(idea_idx)); // dataList 에서 idea_idx 일치하는 행의 index 추출

      updatedDataList[index] = { ...updatedDataList[index], ...jsonData }; // index 행의 정보만 변경
      this.setState({
        dataList: updatedDataList,
      });
    },

    addNewIdea: async (jsonData) => {
      FoodingService.addNewIdea(JSON.stringify(jsonData))
        .then((res) => {
          let ideaIdx = res.data.rows[0].idea_idx;
          this.setState({ activeTableRowIndex: ideaIdx, isNewEdit: true });
          this.showIdeaDetailModal(ideaIdx, res.data.rows[0]);
          if (res.data.rowCount === 1) {
            CommonFunc.openNotification("success", "추가가 완료됐습니다.", "");

            this.filterSearch();
          } else {
            CommonFunc.openNotification("warn", "이미 등록된 의견입니다.");
          }
        })
        .catch((err) => {
          CommonFunc.openNotification("error", "추가를 실패했습니다.", err?.response?.data);
        });
    },
    // FoodingService.updateIdea 서비스 호출
    updateIdea: async (idea_idx, data) => {
      //console.log("updateIdea : " + JSON.stringify(data));
      FoodingService.updateIdea(JSON.stringify(data))
        .then((res) => {
          if (res.status === 200) {
            if (res.data !== "") {
              // CommonFunc.openNotification("success", "수정이 완료됐습니다.", "");

              // 성공시 리스트 갱신
              this.updateHandler.reloadIdeaList(idea_idx, data); // 리스트 갱신
            } else {
              CommonFunc.openNotification("error", "데이터 저장이 실패됐습니다.", JSON.stringify(data));
            }
          } else {
            // Error
            CommonFunc.openNotification("error", "수정을 실패했습니다.", res.status);
          }
        })
        .catch((err) => {
          CommonFunc.openNotification("error", "수정을 실패했습니다.", err?.response?.data);
        });
    },
    deleteIdea: async (idea_idx) => {
      FoodingService.deleteIdea(idea_idx)
        .then((res) => {
          if (res.status === 200) {
            CommonFunc.openNotification("success", "삭제가 완료됐습니다.", "");
            // console.log(res.data);
            // let linkCnt = res.data[0].link_cnt;
            // let linkIdx = res.data[0].link_idx;

            // if (linkCnt === 1) {
            //   this.updateHandler.deleteIdeaLink(linkIdx, linkCnt);
            // }
            this.filterSearch();
          } else {
            // Error
            CommonFunc.openNotification("error", "삭제를 실패했습니다..", res.status);
          }
        })
        .catch((err) => {
          console.dir(err);
          CommonFunc.openNotification("error", "삭제를 실패했습니다.", err?.response?.data);
        });
    },
  };

  // DB 조회 (필터 검색)
  filterSearch = async (refreshCount) => {
    // console.log("filterSearch : " + JSON.stringify(listFilterParams));
    if (this.state.showOnlyMyData) {
      listFilterParams.user_id = this.state.userId;
    } else {
      listFilterParams.user_id = null;
    }

    FoodingService.getIdeaList(JSON.stringify(listFilterParams))
      .then((res) => {
        if (res.status === 200) {
          let dataList = res.data;
          
          if (this.state.isLink) {
            let paramIdeaIdx = parseInt(this.props.match?.params?.ideaIdx);
            let ideaDetailData = dataList.filter(data => data.idea_idx ===paramIdeaIdx);
            this.showIdeaDetailModal(paramIdeaIdx, ideaDetailData[0]);
            this.setState({ isLink: false,activeTableRowIndex: paramIdeaIdx });
          }
          
          // console.log("filter ", dataList);
          // let filteredSpaceList = dataList.filter((data, idx, arr) => {
          //   return arr.findIndex((item) => item.space_title === data.space_title) === idx;
          // });
          // this.setState({
          //   dataList,
          //   spaceDataList: filteredSpaceList,
          //   loading: false,
          // });
          this.setState({
            dataList,
            loading: false,
          });
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
    
    if (!refreshCount) {
      FoodingService.getIdeaListCount(JSON.stringify(listFilterParams))
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              dataListSummary: res.data[0],
            });
          } else {
            CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
          }
        })
        .catch((err) => {
          CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
        });
      
    }
  };

  // 마운트 시 호출
componentDidMount() {
    // this.filterSearch();  // SavedFilter 에서 기본 필터가 있으면 기본필터로... 없으면 전체 리스트 출력 로직 수행됨.
    if (this.props.match?.params?.ideaIdx) {
      this.setState({ isLink: true });
    }
  }
  // 커스텀 필터 TextSearchFilter 에서 호출될 펑션
  setCustomFilteredInfo = (dataIndex, value) => {
    this.setState({
      customFilteredInfo: {
        ...this.state.customFilteredInfo,
        [dataIndex]: value,
      },
      loading: true,
    });
    // Assign filter data
    listFilterParams = {
      ...listFilterParams,
      [dataIndex]: value,
      page_current: 1,
      page_size: this.state.page_size,
    };
    this.filterSearch();
  };

  /* 스페이스 별로 필터가 걸려서 의견 리스트 불러오는 함수, 
  spaceInfo는 SelectedSpaceSummary에서만 들어오는 값 - 이거는 종료된 스페이스인 경우 추가 안되게 권한 내릴려고 사용
  */
  applySpaceFilter = (data, spaceInfo) => {
    // 선택된 스페이스 없는 경우, 의견 리스트를 빈배열, 카운트를 전부 0으로 변경 => 이건 추후에 논의 후 적용
    // console.log(data.space_idx);
    if (data && data.space_idx && data.space_idx.length === 0) {
      this.setState({
        dataList: [],
        dataListSummary: { all_idea_cnt: 0, all_feedback_cnt: 0, all_issue_cnt: 0, total_cnt: 0 },
        loading: false,
      });
      return;
    }

    if (spaceInfo) {
      this.setState({
        selectedSpaceInfo: spaceInfo,
      });
    }

    // this.setState({
    //   filterSpaceIdx: data.space_idx,
    // });

    listFilterParams = {
      // user_id: this.state.userId,
      space_idx: this.state.selectedSpaceIdx,
      page_current: 1,
      page_size: this.state.page_size,
      ...data,
    };

    this.filterSearch();
  };

  // List 변경 이벤트 처리 핸들러
  handleIdeaListChange = (pagination, filters, sorter) => {
    // console.log("test filters", filters);
    // console.log("test sorter", sorter);
        this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      // loading: true,
    });
    // listFilterParams = filters; => 변경했음
    listFilterParams = {
      // 파라미터 전달용 value 세팅 : filters에는 커스텀 필터 값이 포함되어 있지 않아 병합 처리
      ...listFilterParams,
      ...filters,
      ...sorter,
      ...this.state.customFilteredInfo,
      // user_id: this.state.userId,
      page_current: this.state.page_current,
      page_size: this.state.page_size,
    };
    if (!listFilterParams.space_idx) {
      listFilterParams.space_idx = [this.state.selectedSpaceIdx];
    }

    // console.log("handle", listFilterParams);
    this.filterSearch();
  };

  // 페이징 핸들러
  handlePagination = (page, pageSize) => {
    // console.log("handlePagination ", page);
    // console.log("handlePagination ", pageSize);
    if (this.state.page_size !== pageSize) page = 1;

    this.setState({
      page_current: page,
      page_size: pageSize,
      loading: true,
    });
    listFilterParams = {
      ...listFilterParams,
      page_current: page,
      page_size: pageSize,
    };
    this.filterSearch("pagination");
  };

  /* 
    리스트 row 자체에 대한 이벤트, 현재는 클릭에 대한 것만 걸어놨음
    현재는 클릭 했을 때 행 색깔 반전되게해서 지정된 행 선택 안 풀린것처럼 보이게 함
    그리고 상세 화면 모달이 뜸 여기서 데이터 수정 진행.
  */
  handleFoodingListRow = (record, rowIdx) => {
    return {
      onClick: (e) => {
        this.setState({ activeTableRowIndex: record.idea_idx });
        this.showIdeaDetailModal(record.idea_idx, record);
      },
    };
  };

  // 선택된 행인 경우 색 반전 클래스 지정
  setActiveRowClass = (record, rowIndex) => {
    return record.idea_idx === this.state.activeTableRowIndex ? "activeTableRow" : "";
  };

  removeActiveRowClass = () => {
    this.setState({ activeTableRowIndex: -1 });
  };

  // 의견 추가 누르면 실행되는 이벤트 함수, 빈 데이터를 추가하는 함수, 나중에 해당 행을 눌러서 수정
  addFoodingListRow = () => {
    let jsonData = {
      user_id: this.state.userId,
      space_idx: this.state.selectedSpaceIdx ? this.state.selectedSpaceIdx : "",
    };

    this.updateHandler.addNewIdea(jsonData);
  };

  render() {
    let { sortedInfo, filteredInfo, customFilteredInfo } = this.state;
    sortedInfo = sortedInfo || {};

    // 나중에 공백 있고 없고를 정해서 처리해야함
    const scoreOptions = [...config.scoreList, "[공백]"];
    const productNameOptions = [...config.productNameList];
    const problemTypeOptions = [...config.problemTypeList, "[공백]"];
    const priorityOptions = [...config.priorityList, "[공백]"];
    const feedbackOptions = [...config.feedbackList];

    let columns = [
      {
        title: "등록 일시",
        dataIndex: "create_date",
        key: "create_date",
        width: 90,
        sorter: true,
        // sorter: (a, b) => moment(a.create_date).format("YYYYMMDDHHmmssms") - moment(b.create_date).format("YYYYMMDDHHmmssms"),
        sortOrder: sortedInfo.columnKey === "create_date" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (create_date, data) => (
          <div>
            <Tooltip
              title={
                <div>
                  {moment(data.create_date).format("YYYY-MM-DD HH:mm:ss")}
                  {/* 일단 현재는 관리자만 삭제할 수 있음 */}
                  {(sessionStorage.getItem("admin") || this.state.userId === data.user_id) && (
                    <Button
                      type="text"
                      size="small"
                      className="tt-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Popconfirm title="해당 아이디어를 삭제하시겠습니까?" onConfirm={(value, obj) => this.updateHandler.deleteIdea(data.idea_idx)} okText="삭제" cancelText="취소">
                        <DeleteOutlined />
                      </Popconfirm>
                    </Button>
                  )}
                </div>
              }
              color="#7d8282"
              align="center"
              placement="top"
            >
              <span>{moment(data.create_date).fromNow()}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        title: "스페이스 이름",
        dataIndex: "space_title",
        key: "space_title",
        width: 150,
        ellipsis: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "space_title" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        // filters: this.state.spaceDataList?.map((space) => ({ text: space.space_title, value: space.space_title })),
        // filteredValue: filteredInfo?.space_title ? filteredInfo.space_title : null,
      },
      {
        title: "제품명",
        dataIndex: "idea_type_idx",
        key: "idea_type_idx",
        width: 100,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "idea_type_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: productNameOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.idea_type_idx ? filteredInfo.idea_type_idx : null,
        render: (problem_type_idx, data) => <div className="tdText">{productNameOptions[problem_type_idx - 1]}</div>,
      },
      // {
      //   title: "메뉴명 또는 화면 위치",
      //   dataIndex: "idea_title",
      //   key: "idea_title",
      //   width: 180,
      //   ellipsis: true,
      //   sorter: true,
      //   sortOrder: sortedInfo.columnKey === "idea_title" && sortedInfo.order,
      //   sortDirections: ["descend", "ascend"],
      //   filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="idea_title" />,
      //   filteredValue: customFilteredInfo?.idea_title ? [customFilteredInfo?.idea_title] : null,
      //   filterIcon: (filtered) => (
      //     <SearchOutlined
      //       style={{
      //         color: customFilteredInfo?.idea_title ? "#1890ff" : undefined,
      //       }}
      //     />
      //   ),
      // },
      {
        title: "제안 의견",
        dataIndex: "idea_contents",
        key: "idea_contents",
        width: 400,
        ellipsis: true,
        filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="idea_contents" />,
        filteredValue: customFilteredInfo?.idea_contents ? customFilteredInfo?.idea_contents : null,
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: customFilteredInfo?.idea_contents && customFilteredInfo?.idea_contents.length > 0 ? "#1890ff" : undefined,
            }}
          />
        ),
        render: (idx, data) => (
          <>
            <Tooltip title={data.idea_contents} color="#7d8282" placement="topLeft">
              {data.idea_contents}
            </Tooltip>
          </>
        ),
      },
      // 기존에 user_id로 내의견 다른사람의견을 필터값으로 사용해서 이것만 필터를 writer_id로 바꿔서 사용하고 있다.
      {
        title: "작성자",
        dataIndex: "user_id",
        key: "user_id",
        width: 150,
        ellipsis: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "user_id" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],

        filterDropdown: !this.state.showOnlyMyData ? (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="writer_id" /> : null,
        filteredValue: !this.state.showOnlyMyData ? (customFilteredInfo?.writer_id ? customFilteredInfo?.writer_id : null) : null,
        filterIcon: !this.state.showOnlyMyData
          ? (filtered) => (
              <SearchOutlined
                style={{
                  color: customFilteredInfo?.writer_id && customFilteredInfo?.writer_id.length > 0 ? "#1890ff" : undefined,
                }}
              />
            )
          : null,
      },
      {
        title: "좋아요",
        dataIndex: "like_cnt",
        key: "like_cnt",
        width: 70,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "like_cnt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (like_cnt, data) => (
          <Space>
            <LikeTwoTone />
            {like_cnt}
          </Space>
        ),
      },
      {
        title: "댓글",
        dataIndex: "comment_cnt",
        key: "comment_cnt",
        width: 70,
        className: "fixed-col",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "comment_cnt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (comment_cnt, data) => (
          <Space>
            <MessageTwoTone />
            {comment_cnt}
          </Space>
        ),
      },
      {
        title: "링크",
        dataIndex: "link_cnt",
        key: "link_cnt",
        width: 70,
        className: "fixed-col",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "link_cnt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (link_cnt, data) => (
          <>
            <Button icon={<LinkOutlined />} style={{ cursor: "default" }} type="link"></Button>
            {link_cnt}
          </>
        ),
      },
      
      {
        title: "검토 현황",
        dataIndex: "feedback_idx",
        key: "feedback_idx",
        width: 100,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "feedback_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: feedbackOptions.map((label, index) =>  ({ text: label, value: index })),
        filteredValue: filteredInfo?.feedback_idx ? filteredInfo.feedback_idx : null,
        render: (feedback_idx, data) => <div className="tdText" style={{ color: feedbackOptions[feedback_idx] === "미확인" ? "red" : (feedbackOptions[feedback_idx] === "미채택" ? "grey" : "black") }}>{feedbackOptions[feedback_idx]}</div>,
      },
      {
        title: "의견 분류",
        dataIndex: "idea_category_idx",
        key: "idea_category_idx",
        width: 100,
        ellipsis: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "idea_category_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: problemTypeOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.idea_category_idx ? filteredInfo.idea_category_idx : null,
        render: (idea_category_idx, data) => <div className="tdText">{problemTypeOptions[idea_category_idx - 1]}</div>,
      },
      {
        title: "중요도",
        dataIndex: "priority_idx",
        key: "priority_idx",
        className: "fixed-col",
        width: 90,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "priority_idx" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: scoreOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.priority_idx ? filteredInfo.priority_idx : null,
        render: (priority_idx, data) => (
          <div className="tdText">
            <Badge
              color={
                priorityOptions[priority_idx - 1] === "높음" ? "#f50" : config.priorityList[priority_idx - 1] === "보통" ? "#87d068" : config.priorityList[priority_idx - 1] === "낮음" ? "#efefef" : ""
              }
              text={config.priorityList[priority_idx - 1]}
            />
          </div>
        ),
      },
      {
        title: "기여도",
        dataIndex: "idea_score",
        key: "idea_score",
        width: 90,
        className: "fixed-col",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "idea_score" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filters: scoreOptions.map((label, index) => (label === "[공백]" ? { text: "[공백]", value: 0 } : { text: label, value: index + 1 })),
        filteredValue: filteredInfo?.idea_score ? filteredInfo.idea_score : null,
        render: (score_idx, data) => (
          <div className="tdText">
            {score_idx === 0 ? (
              ""
            ) : (
              <Space>
                <StarTwoTone twoToneColor="#FFB900" />
                {scoreOptions[score_idx - 1]}
              </Space>
            )}
          </div>
        ),
      },
      {
        title: "채택 정보",
        dataIndex: "issue_number",
        key: "issue_number",
        width: 100,
        ellipsis: true,
        className: "fixed-col",
        //sorter: (a, b) => a.issue_number.length - b.issue_number.length,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "issue_number" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        filterDropdown: (props) => <TextSearchFilter {...props} setCustomFilteredInfo={this.setCustomFilteredInfo} dataIndex="issue_number" />,
        filteredValue: customFilteredInfo?.issue_number ? [customFilteredInfo?.issue_number] : null,
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: customFilteredInfo?.issue_number ? "#1890ff" : undefined,
            }}
          />
        ),
        // render: (number, data) => ({
        /*
          <>
            <div className="f-left">
              {number === "임시" ? (
                number
              ) : (
                <a href={"http://redmine.somansa.com/redmine/issues/show/" + number} target="_blank" rel="noopener noreferrer">
                  #{number}
                </a>
              )}
            </div>
            <div className="ta-right w-100">
              {number !== "임시" ? (
                <Button
                  type="default"
                  icon={<SyncOutlined />}
                  className="icon-gray"
                  shape="circle"
                  size="small"
                  title="일감 동기화"
                  onClick={() =>
                    this.syncTargetCompare(
                      data.issue_idx,
                      data.issue_number,
                      data.issue_status,
                      data.issue_subject,
                      data.issue_assigned_to
                    )
                  }
                />
              ) : (
                ""
              )}
              <Button
                type="default"
                icon={<BorderlessTableOutlined />}
                className="icon-gray"
                shape="circle"
                size="small"
                title="일감 번호 수정"
                onClick={() =>
                  this.modalHandler.showIssueNumberModal(data.issue_idx, number)
                }
              /> 
           </div>
          </>
        ),
      }, */
        // }),
      },
    ];

    const tableLoading = {
      spinning: this.state.loading,
      indicator: <Spin />,
    };

    if (!sessionStorage.getItem("admin")) {
      columns = columns.filter(column => column.dataIndex !== "idea_category_idx");
    }

    return (
      <Fragment>
        <Row gutter={[10, 10]}>
          <Col>
            {/* {!sessionStorage.getItem("admin") && this.state.selectedSpaceIdx && (
              <Button
                ghost
                type="primary"
                shape="round"
                onClick={(e) => {
                  e.preventDefault();
                  const currentEnterSpace = this.state.selectedSpaceIdx;
                  this.setState(
                    {
                      currentEnterSpace,
                      selectedSpaceIdx: null,
                    },
                    () => {
                      this.props.history.replace("/fd/list/" + this.state.userId);
                    }
                  );
                }}
                size="middle"
                style={{
                  marginRight: 4,
                }}
                className="btn-new"
              >
                전체 스페이스 보기
              </Button>
            )} */}
            {/* {!this.state.selectedSpaceIdx && !sessionStorage.getItem("admin") && (
              <Button
                ghost
                type="primary"
                shape="round"
                onClick={(e) => {
                  e.preventDefault();
                  const currentEnterSpace = this.state.currentEnterSpace;
                  if (!currentEnterSpace) {
                    alert("잘못된 경로로 접근하셨습니다.");
                    this.props.history.replace(`/fd/select/${this.state.userId}`);
                    return;
                  }
                  this.setState(
                    {
                      currentEnterSpace: null,
                      selectedSpaceIdx: currentEnterSpace,
                    },
                    () => {
                      this.props.history.replace(`/fd/list/${this.state.userId}/${this.state.selectedSpaceIdx}`);
                    }
                  );
                  // this.props.history.replace(this.state.currentUrl);
                }}
                size="middle"
                style={{
                  marginRight: 4,
                }}
                className="btn-new"
              >
                다시 선택한 스페이스 이동
              </Button>
            )} */}
            {!sessionStorage.getItem("admin") && (
              <Button
                ghost
                type="primary"
                shape="round"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.replace(`/fd/select/${this.state.userId}`);
                }}
                size="middle"
                style={{
                  marginRight: 4,
                }}
                className="btn-new"
              >
                스페이스 선택 화면으로 이동
              </Button>
            )}
          </Col>
          <Card style={{ width: "100%" }}>
            <Row gutter={[4, 4]} justify="space-between" align="middle">
              <Col span={16}>
                <Space size="large">
                  {this.state.selectedSpaceIdx && (
                    <SelectedSpaceSummary history={this.props.history} userId={this.state.userId} spaceIdx={this.state.selectedSpaceIdx} applySpaceFilter={this.applySpaceFilter} />
                  )}
                  {!this.state.selectedSpaceIdx && <SpaceDropdown applySpaceFilter={this.applySpaceFilter} initFilter={this.initFilter} userId={this.state.userId} />}
                </Space>
              </Col>
              <Col span={8}>
                <IdeaCountSummary dataListSummary={this.state.dataListSummary} clickIdeaCountSummary={this.clickIdeaCountSummary} />
              </Col>
            </Row>
          </Card>
          <Col span={24}>
            {/* 
                관리자가 아니고 (필수)
                공개여부가 공개일때  => 어차피 접근 권한 막아짐
                스페이스 idx가 있고 (필수) 
                (의견 작성 대상이 전체(0)이거나 
                의견 작성 대상이 특정대상(1)일때는 adminUserList에 포함된 계정만 글 남길 수 있음) (필수)
                스페이스가 Open 된 상태 (필수)
                (스페이스 기간이 상시이거나 기간설정이었을때는 그 날짜이전이어야 작성 가능) (필수)

            */}
            {!sessionStorage.getItem("admin") &&
              this.state.selectedSpaceIdx &&
              ((this.state.selectedSpaceInfo?.space_target_type === 1 && config.adminUserList.includes(this.state.userId)) || this.state.selectedSpaceInfo?.space_target_type === 0) &&
              this.state.selectedSpaceInfo?.space_state === 0 &&
              (this.state.selectedSpaceInfo?.space_date_type === 0 ||
                (this.state.selectedSpaceInfo?.space_date_type === 1 && moment().diff(moment(this.state.selectedSpaceInfo?.space_end_date)) < 0)) && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addFoodingListRow();
                  }}
                  size="middle"
                  style={{
                    backgroundColor: "#40a9ff",
                    borderColor: "#40a9ff",
                    marginBottom: 8,
                    marginRight: 4,
                  }}
                  className="btn-new"
                >
                  의견 추가
                </Button>
              )}
            {/*
                관리자가 아니고 (필수)
                공개여부가 공개일때  => 어차피 접근 권한 막아짐
                Close 시 (space_share_type이 1이면) - state가 close(1)이거나 기간 지났을 때이거나
                모든 의견 보기 였을 경우
             */}
            {!sessionStorage.getItem("admin") &&
              ((this.state.selectedSpaceInfo?.space_share_type === 1 &&
                (this.state.selectedSpaceInfo?.space_state === 1 ||
                  (this.state.selectedSpaceInfo?.space_date_type === 1 && moment().diff(moment(this.state.selectedSpaceInfo?.space_end_date)) > 0))) ||
                this.state.selectedSpaceInfo?.space_share_type === 2) && (
                <Button
                  ghost
                  type="primary"
                  shape="round"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      (prevState) => ({
                        ...prevState,
                        showOnlyMyData: !prevState.showOnlyMyData,
                      }),
                      () => {
                        this.filterSearch();
                      }
                    );
                  }}
                  size="middle"
                  style={{
                    marginRight: 4,
                  }}
                  className="btn-new"
                >
                  {this.state.showOnlyMyData ? "모든 의견 보기" : "내 의견만 보기"}
                </Button>
              )}
            <Table
              key="defaultTable"
              columns={columns}
              size="middle"
              dataSource={this.state.dataList}
              onChange={this.handleIdeaListChange}
              onRow={this.handleFoodingListRow}
              rowClassName={this.setActiveRowClass}
              showSorterTooltip={false}
              rowKey="idea_idx"
              pagination={false}
              scroll={{ x: 1500 }}
              loading={tableLoading}
            />
            <PageHeader
              title="SOMANSA"
              subTitle="Made by UX team"
              extra={[
                <Pagination
                  key={"pagination"}
                  size="small"
                  current={this.state.page_current}
                  pageSize={this.state.page_size}
                  total={this.state.dataListSummary?.total_cnt}
                  showSizeChanger={true}
                  pageSizeOptions={config.pageSizeOptions}
                  onChange={(page, pageSize) => this.handlePagination(page, pageSize)}
                />,
              ]}
            />
          </Col>
        </Row>

        <IdeaDetailModal
          key={"IdeaDetailModal" + this.state.ideaDetailModalRerender}
          ideaDetailData={this.state.ideaDetailData}
          isIdeaDetailModalVisible={this.state.isIdeaDetailModalVisible}
          ideaIdx={this.state.ideaIdx}
          userId={this.state.userId}
          isNewEdit={this.state.isNewEdit}
          changeEditState={this.changeEditState}
          updateIdea={this.updateHandler.updateIdea}
          reloadIdeaLink={this.updateHandler.reloadIdeaLink}
          reloadIdeaComment={this.updateHandler.reloadIdeaComment}
          reloadIdeaLike={this.updateHandler.reloadIdeaLike}
          removeActiveRowClass={this.removeActiveRowClass}
          deleteIdea={this.updateHandler.deleteIdea}
          reloadSummaryCnt={this.updateHandler.reloadSummaryCnt}
        />
      </Fragment>
    );
  }
}
