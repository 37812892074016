import React, { Component } from "react";
import { Modal, Input, Row, Col, Radio, Space, DatePicker, Button } from "antd";
import * as CommonFunc from "../components/functions/CommonFunc.js";
import FoodingService from "../../service/FoodingService";
import moment from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

/*
  스페이스 추가 및 수정 모달 
*/
export default class SpaceModal extends Component {
  state = {
    selectedState: this.props.spaceData ? this.props.spaceData.space_state : 0,
    selectedDateType: this.props.spaceData ? this.props.spaceData.space_date_type : 0,
    selectedTargetType: this.props.spaceData ? this.props.spaceData.space_target_type : 0,
    selectedShareType: this.props.spaceData ? this.props.spaceData.space_share_type : 0,
    selectedPublishType: this.props.spaceData ? this.props.spaceData.space_publish_type : 0,
    inputTitle: this.props.spaceData ? this.props.spaceData.space_title : "",
    inputDescription: this.props.spaceData ? this.props.spaceData.space_description : "",
    selectedStartDate: this.props.spaceData ? this.props.spaceData.space_start_date : null,
    selectedEndDate: this.props.spaceData ? this.props.spaceData.space_end_date : null,
    isSpaceModalVisible: this.props.isSpaceModalVisible,
  };

  addNewSpace = () => {
    const jsonData = {
      space_state: this.state.selectedState,
      space_date_type: this.state.selectedDateType,
      space_target_type: this.state.selectedTargetType,
      space_share_type: this.state.selectedShareType,
      space_publish_type: this.state.selectedPublishType,
      space_title: this.state.inputTitle,
      space_description: this.state.inputDescription,
      space_start_date: this.state.selectedStartDate,
      space_end_date: this.state.selectedEndDate,
    };

    FoodingService.addNewSpace(JSON.stringify(jsonData))
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          CommonFunc.openNotification("success", "추가가 완료됐습니다.", "");
          this.props.onReloadSpace();
        } else {
          CommonFunc.openNotification("error", "추가를 실패했습니다.", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "추가를 실패했습니다.", err?.response?.data);
      });

    this.setState({ isSpaceModalVisible: false });
  };

  updateSpace = () => {
    const jsonData = {
      space_state: this.state.selectedState,
      space_date_type: this.state.selectedDateType,
      space_target_type: this.state.selectedTargetType,
      space_share_type: this.state.selectedShareType,
      space_publish_type: this.state.selectedPublishType,
      space_title: this.state.inputTitle,
      space_description: this.state.inputDescription,
      space_start_date: this.state.selectedStartDate,
      space_end_date: this.state.selectedEndDate,
      space_idx: this.props.spaceData.space_idx,
    };

    FoodingService.updateSpace(JSON.stringify(jsonData))
      .then((res) => {
        console.log(res);
        if (res.data.rowCount === 1) {
          CommonFunc.openNotification("success", "수정이 완료됐습니다.", "");
          this.props.onReloadSpace();
        } else {
          CommonFunc.openNotification("error", "추가를 실패했습니다.", res.status);
        }
      })
      .catch((err) => {
        CommonFunc.openNotification("error", "수정을 실패했습니다.", err?.response?.data);
      });

    this.setState({ isSpaceModalVisible: false });
  };

  closeSpaceModal = () => {
    this.setState({ isSpaceModalVisible: false });
  };

  onChangeState = (e) => {
    this.setState({
      selectedState: e.target.value,
    });
  };

  onChangeDateType = (e) => {
    this.setState({
      selectedDateType: e.target.value,
    });
  };

  onChangeTargetType = (e) => {
    this.setState({
      selectedTargetType: e.target.value,
    });
  };

  onChangeShareType = (e) => {
    this.setState({
      selectedShareType: e.target.value,
    });
  };

  onChangePublishType = (e) => {
    this.setState({
      selectedPublishType: e.target.value,
    });
  };

  onChangeDatePicker = (date) => {
    if (!date) {
      return;
    }
    const [startDate, endDate] = date;
    console.log(moment(endDate).format("YYYY-MM-DD"));
    this.setState({
      selectedStartDate: moment(startDate).format("YYYY-MM-DD"),
      selectedEndDate: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  // props로 가져온 space 정보가 있는지 없는지로 수정, 추가를 구분
  render() {
    return (
      <Modal
        title={this.props.spaceData ? "스페이스 수정" : "새 스페이스 추가"}
        visible={this.state.isSpaceModalVisible}
        okText="저장"
        cancelText="취소"
        onOk={this.props.spaceData ? this.updateSpace : this.addNewSpace}
        onCancel={this.closeSpaceModal}
        width={600}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {this.props.spaceData && (
                <Button
                  danger
                  onClick={() => {
                    this.props.deleteSpace(this.props.spaceData.space_idx);
                    this.closeSpaceModal();
                  }}
                >
                  삭제
                </Button>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <Button onClick={this.closeSpaceModal}>취소</Button>
              <Button type="primary" onClick={this.props.spaceData ? this.updateSpace : this.addNewSpace}>
                저장
              </Button>
            </div>
          </div>
        }
      >
        <Row gutter={[12, 12]}>
          <Col span={6}>상태</Col>
          <Col span={18}>
            <Radio.Group onChange={this.onChangeState} value={this.state.selectedState}>
              <Radio value={0}>Open</Radio>
              <Radio value={1} disabled={this.props.spaceData ? false : true}>
                Close
              </Radio>
            </Radio.Group>
          </Col>
          <Col span={6}>기간</Col>
          <Col span={18}>
            <Radio.Group onChange={this.onChangeDateType} value={this.state.selectedDateType}>
              <Radio value={0}>상시</Radio>
              <Radio value={1}>
                기간 설정
                {this.state.selectedDateType === 1 ? (
                  this.props.spaceData && this.state.selectedStartDate ? (
                    <RangePicker
                      defaultValue={[moment(this.state.selectedStartDate), moment(this.state.selectedEndDate)]}
                      style={{ marginLeft: "8px" }}
                      locale={locale}
                      onChange={this.onChangeDatePicker}
                    />
                  ) : (
                    <RangePicker style={{ marginLeft: "8px" }} locale={locale} onChange={this.onChangeDatePicker} />
                  )
                ) : null}
              </Radio>
            </Radio.Group>
          </Col>
          <Col span={6}>의견 작성 대상</Col>
          <Col span={18}>
            <Radio.Group onChange={this.onChangeTargetType} value={this.state.selectedTargetType}>
              <Radio value={0}>전체 </Radio>
              <Radio value={1}>특정대상</Radio>
            </Radio.Group>
          </Col>
          <Col span={6}>의견 보기 설정</Col>
          <Col span={18}>
            <Radio.Group onChange={this.onChangeShareType} value={this.state.selectedShareType}>
              <Space direction="vertical">
                <Radio value={0}>내 의견만 보기</Radio>
                <Radio value={1}>Close 시, 모든 의견 보기</Radio>
                <Radio value={2}>모든 의견 보기</Radio>
              </Space>
            </Radio.Group>
          </Col>
          <Col span={6}>공개 여부</Col>
          <Col span={18}>
            <Radio.Group onChange={this.onChangePublishType} value={this.state.selectedPublishType}>
              <Radio value={0}>공개</Radio>
              <Radio value={1} disabled={this.state.selectedTargetType === 0}>
                특정 대상
              </Radio>
              <Radio value={2}>비공개</Radio>
            </Radio.Group>
          </Col>
          <Col span={6}>이름</Col>
          <Col span={18}>
            <Input type="text" value={this.state.inputTitle} autoFocus={true} onChange={(e) => this.setState({ inputTitle: e.target.value })} />
          </Col>
          <Col span={6}>설명</Col>
          <Col span={18}>
            <TextArea type="text" value={this.state.inputDescription} autoFocus={true} onChange={(e) => this.setState({ inputDescription: e.target.value })} />
          </Col>
        </Row>
      </Modal>
    );
  }
}
