import React, { Component } from "react";
import "antd/dist/antd.compact.css";
import FoodingService from "../../service/FoodingService";
import * as CommonFunc from "../components/functions/CommonFunc.js";
import { Typography, Space, Row, Col, Tag } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import moment from "moment";
const config = require("../../config/config.js");

export default class SelectedSpaceSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSpaceInfo: null,
    };
  }

  componentDidMount() {
    console.log(this.props.history);
    if (!this.props.spaceIdx) {
      alert("잘못된 경로로 접근하셨습니다.");
      this.props.history.replace(`/fd/select/${this.props.userId}`);
    } else {
      this.getSpaceListByIdx();
    }
  }

  // 스페이스 idx를 통해 해당 스페이스 정보를 가져온다. 이후에 그걸로 필터 조회해서 리스트 업데이트.
  getSpaceListByIdx() {
    let jsonData = { space_idx: this.props.spaceIdx };
    FoodingService.getSpaceList(JSON.stringify(jsonData))
      .then((res) => {
        if (res.status === 200) {
          let spaceInfo = res.data[0];
          // 스페이스 정보가 없거나 비공개인 경우, 아니면 특정대상인 경우면 adminUserList로 된 사람만 접근됨
          if (!spaceInfo || spaceInfo.space_publish_type === 2 || (spaceInfo.space_publish_type === 1 && !config.adminUserList.includes(this.props.userId))) {
            alert("잘못된 경로로 접근하셨습니다.");
            this.props.history.replace(`/fd/select/${this.props.userId}`);
            return;
          }
          this.setState(
            {
              selectedSpaceInfo: spaceInfo,
            },
            () => {
              this.props.applySpaceFilter({ space_idx: [spaceInfo.space_idx] }, spaceInfo);
            }
          );
        } else CommonFunc.openNotification("error", "조회를 실패했습니다.", res.status);
      })
      .catch((err) => {
        console.dir(err);
        CommonFunc.openNotification("error", "조회를 실패했습니다.", err?.response?.data);
      });
  }
  render() {
    if (!this.state.selectedSpaceInfo) return null;
    const { space_title, space_description } = this.state.selectedSpaceInfo;

    // 현재는 close된 스페이스나, 기간 종료된 스페이스인 경우만 종료된 스페이스를 표시
    return (
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Space size="large">
            <Typography.Title level={4}>{space_title}</Typography.Title>
            {/* 상태가 Close이거나 기간설정이 되었는데, 기간이 지난 경우 */}
            {(this.state.selectedSpaceInfo?.space_state === 1 || (this.state.selectedSpaceInfo?.space_date_type === 1 && moment().diff(moment(this.state.selectedSpaceInfo?.space_end_date)) > 0)) && (
              <Tag icon={<WarningTwoTone twoToneColor="#F3C62D" />} color="#808080" style={{ padding: 12 }}>
                종료된 스페이스 입니다.
              </Tag>
            )}
          </Space>
        </Col>
        <Col span={24}>
          <Typography.Text>
            {space_description.split("\n").map((line, index) => {
              return (
                <span key={`line${index}`}>
                  {line}
                  <br />
                </span>
              );
            })}
            {/* 신입 사원 교육 수행 중 제품 데모 시연을 하면서 확인된 오류, 버그,
              <br />
              이해가 어려운 내용 또는 개선 의견을 남겨 주세요. */}
          </Typography.Text>
        </Col>
      </Row>
    );
  }
}
