import React, { useState, Component, Fragment } from "react";
import { Row, Col, Button, Space, Avatar, Divider } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

const UserList = ["A", "B", "C", "D"];
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae", "#eb2f96"];
// 색상은 랜덤으로 배정되게 해주세요.

const Autoset = ({ userId }) => {
  const [user] = useState(userId ? userId[0] : UserList[1]);
  const [color] = useState(ColorList[0]);

  return (
    <Avatar style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }} color={color}>
      {user}
    </Avatar>
  );
};
export default class Header extends Component {
  state = {
    userId: this.props.userId,
  };

  logout = () => {
    this.props.history.replace("/login");

    if (sessionStorage.getItem("admin")) {
      sessionStorage.removeItem("admin");
    }
  };

  renderUserInfo = () => {
    return (
      <Col span={4} offset={10} align="end" className="Header-icn">
        <Space size={8}>
          <Autoset userId={this.props.userId} />
          {this.props.userId}
          <Divider type="vertical" orientationMargin="2" />
          <Button type="link" onClick={this.logout} style={{ padding: 0 }}>
            Logout
            <LogoutOutlined />
          </Button>
        </Space>
      </Col>
    );
  };

  render() {
    return (
      <Fragment>
        <Row align="middle" justify="space-between" style={{ height: "80px" }}>
          <Col span={6} align="start" className="Header-logo">
            <a href={this.props.currentUrl}>
              <img src={`${process.env.PUBLIC_URL}/Fooding_Logo.svg`} width="210px" alt="Fooding" />
            </a>
          </Col>

          {/* 관리자로 로그인 된 상태에서 관리페이지로 들어가면 보임 */}
          {sessionStorage.getItem("admin") && this.props.history.location?.pathname?.indexOf("/fd/admin") !== -1 && this.renderUserInfo()}
          {/* 일반사용자의 경우에만 보임*/}
          {!sessionStorage.getItem("admin") && this.props.history.location?.pathname?.indexOf("/fd/admin") === -1 && this.renderUserInfo()}
        </Row>
      </Fragment>
    );
  }
}
